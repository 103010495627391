import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Autocomplete, CardActions, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import Web3 from 'web3';
import { isHexPrefixed } from 'web3-validator';

const rpcs = ['https://rpc.ankr.com/eth', 'https://binance.llamarpc.com'];

function Call() {
  const [rpc, setRPC] = useState("")
  const [from, setFrom] = useState("0x0000000000000000000000000000000000000000");
  const [to, setTo] = useState("");
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const [gasLimit, setGasLimit] = useState("0");
  const [gasPrice, setGasPrice] = useState("0");
  const [callButtonLoading, setCallButtonLoading] = useState(false);

  const [result, setResult] = useState("");

  async function handleSend() {
    setCallButtonLoading(true);

    let parsedValue: string = "";
    try {
      parsedValue = Web3.utils.toWei(value, 'ether');
    } catch (error) {
      setCallButtonLoading(false);
      alert('Failed to parse value: ' + (error as Error).message);
      return;
    }
    let parsedGasPrice: string = "";
    try {
      parsedGasPrice = Web3.utils.toWei(gasPrice, 'gwei');
    } catch (error) {
      setCallButtonLoading(false);
      alert('Failed to parse gasPrice: ' + (error as Error).message);
      return;
    }
    let parsedData: string = data;
    if (!isHexPrefixed(parsedData)) parsedData = '0x' + parsedData;
    let transactionObject = {
      from: from,
      to: to,
      value: parsedValue,
      data: parsedData,
      gas: gasLimit,
      gasPrice: parsedGasPrice,
    };

    let shouldSetButton = true;
    try {
      setResult(await new Web3(rpc).eth.call(transactionObject));
      shouldSetButton = false;
      setCallButtonLoading(false);
    } catch (error) {
      if (shouldSetButton) {
        shouldSetButton = false;
        setCallButtonLoading(false);
        alert('Failed to call: ' + (error as Error).message);
      }
    }
  }

  return (
    <Card>
      <CardContent sx={{
        mb: 0,
        pb: 0,
      }}>
        <Typography variant="h5" component="div" sx={{
          userSelect: 'none',
          pl: 1,
          pb: 1,
        }}
          onDragStart={(e) => { e.preventDefault(); }}>
          Call
        </Typography>

        <Autocomplete sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          freeSolo
          size="small"
          options={rpcs}
          renderInput={
            (params) => <TextField spellCheck="false" margin="normal" {...params} label="RPC" onChange={(e) => setRPC(e.target.value)} value={rpc} />
          }
          onInputChange={(_e, v) => setRPC(v)}
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="From"
          onChange={(e) => setFrom(e.target.value)}
          value={from}
          margin="normal"
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="To"
          onChange={(e) => setTo(e.target.value)}
          value={to}
          margin="normal"
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="Value(Ether)"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          margin="normal"
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="Data"
          multiline
          rows={6}
          onChange={(e) => setData(e.target.value)}
          value={data}
          margin="normal"
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="GasLimit"
          onChange={(e) => setGasLimit(e.target.value)}
          value={gasLimit}
          margin="normal"
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="GasPrice(GWei)"
          onChange={(e) => setGasPrice(e.target.value)}
          value={gasPrice}
          margin="normal"
        />

        <Grid container justifyContent="flex-end">
          <LoadingButton sx={{
            mt: 1,
            mb: 1,
          }}
            size="small"
            variant="contained"
            onClick={handleSend}
            loading={callButtonLoading}
          >Call</LoadingButton>
        </Grid>

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          fullWidth
          label="Result"
          multiline
          rows={6}
          value={result}
          onChange={(e) => setResult(e.target.value)}
          margin="normal"
        />
      </CardContent>

      <CardActions sx={{
        pb: 0.5,
      }}></CardActions>
    </Card>
  );
}

export default Call;