import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Autocomplete, CardActions, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import Web3 from 'web3';

const rpcs = ['https://rpc.ankr.com/eth', 'https://binance.llamarpc.com'];

function GetStorage() {
  const [rpc, setRPC] = useState("");
  const [slot, setSlot] = useState("");
  const [contract, setContract] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [getting, setGetting] = useState(false);

  const [result, setResult] = useState("");

  async function handleGet() {
    setGetting(true);

    try {
      setResult(await new Web3(rpc).eth.getStorageAt(contract, slot, blockNumber !== "" ? blockNumber : "latest"));
    } catch (error) {
      alert((error as Error).message);
    }

    setGetting(false);
  }

  return (
    <Card>
      <CardContent sx={{
        mb: 0,
        pb: 0,
      }}>
        <Typography variant="h5" component="div" sx={{
          userSelect: 'none',
          pl: 1,
          pb: 1,
        }}
          onDragStart={(e) => { e.preventDefault(); }}
        >
          Get Storage
        </Typography>

        <Autocomplete
          spellCheck="false"
          freeSolo
          size="small"
          options={rpcs}
          renderInput={
            (params) => <TextField margin="normal" spellCheck="false" {...params} label="RPC" onChange={(e) => setRPC(e.target.value)} value={rpc} />
          }
          onInputChange={(_e, v) => setRPC(v)}
          sx={{
            userSelect: 'none',
          }}
        />

        <TextField sx={{
          mt: 1,
          userSelect: 'none',
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="Contract"
          onChange={(e) => setContract(e.target.value)}
          value={contract}
          margin="normal"

        />

        <TextField sx={{
          mt: 1,
          userSelect: 'none',
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="Slot"
          onChange={(e) => setSlot(e.target.value)}
          value={slot}
          margin="normal"
        />

        <TextField sx={{
          mt: 1,
          userSelect: 'none',
        }}
          spellCheck="false"
          size="small"
          fullWidth
          label="BlockNumber"
          placeholder="latest"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setBlockNumber(e.target.value)}
          value={blockNumber}
          margin="normal"
        />


        <Grid container justifyContent="flex-end">
          <LoadingButton sx={{
            mt: 1,
            mb: 1,
          }}
            size="small"
            variant="contained"
            onClick={handleGet}
            loading={getting}
          >Get</LoadingButton>
        </Grid>


        <TextField sx={{
          mt: 1,
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          label="Result"
          multiline
          rows={6}
          value={result}
          onChange={(e) => setResult(e.target.value)}
          margin="normal"
        />
      </CardContent>

      <CardActions sx={{
        pb: 0.5,
      }}></CardActions>
    </Card>
  );
}

export default GetStorage;