import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActions, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import Web3 from 'web3';

export default function UnitConverter() {
  const [wei, setWei] = useState("");
  const [kWei, setKWei] = useState("");
  const [mWei, setMWei] = useState("");
  const [gWei, setGWei] = useState("");
  const [szabo, setSzabo] = useState("");
  const [finney, setFinney] = useState("");
  const [ether, setEther] = useState("");
  const [kEther, setKEther] = useState("");
  const [mEther, setMEther] = useState("");
  const [gEther, setGEther] = useState("");
  const [tEther, setTEther] = useState("");

  function updateResult(rawValue: string, rawUnit: string) {
    if (!rawValue) {
      setWei("");
      setKWei("");
      setMWei("");
      setGWei("");
      setSzabo("");
      setFinney("");
      setEther("");
      setKEther("");
      setMEther("");
      setGEther("");
      setTEther("");
      return;
    }

    let rawWei: string;
    try {
      rawWei = Web3.utils.toWei(rawValue, rawUnit as any);
    } catch (_error) {
      if (rawUnit !== 'wei') setWei("");
      if (rawUnit !== 'kwei') setKWei("");
      if (rawUnit !== 'mwei') setMWei("");
      if (rawUnit !== 'gwei') setGWei("");
      if (rawUnit !== 'szabo') setSzabo("");
      if (rawUnit !== 'finney') setFinney("");
      if (rawUnit !== 'ether') setEther("");
      if (rawUnit !== 'kether') setKEther("");
      if (rawUnit !== 'mether') setMEther("");
      if (rawUnit !== 'gether') setGEther("");
      if (rawUnit !== 'tether') setTEther("");
      return;
    }

    if (!rawWei || rawWei === "0") {
      if (rawUnit !== 'wei') setWei("0");
      if (rawUnit !== 'kwei') setKWei("0");
      if (rawUnit !== 'mwei') setMWei("0");
      if (rawUnit !== 'gwei') setGWei("0");
      if (rawUnit !== 'szabo') setSzabo("0");
      if (rawUnit !== 'finney') setFinney("0");
      if (rawUnit !== 'ether') setEther("0");
      if (rawUnit !== 'kether') setKEther("0");
      if (rawUnit !== 'mether') setMEther("0");
      if (rawUnit !== 'gether') setGEther("0");
      if (rawUnit !== 'tether') setTEther("0");
      return;
    }

    if (rawUnit !== 'wei') setWei(Web3.utils.fromWei(rawWei, 'wei'));
    if (rawUnit !== 'kwei') setKWei(Web3.utils.fromWei(rawWei, 'kwei'));
    if (rawUnit !== 'mwei') setMWei(Web3.utils.fromWei(rawWei, 'mwei'));
    if (rawUnit !== 'gwei') setGWei(Web3.utils.fromWei(rawWei, 'gwei'));
    if (rawUnit !== 'szabo') setSzabo(Web3.utils.fromWei(rawWei, 'szabo'));
    if (rawUnit !== 'finney') setFinney(Web3.utils.fromWei(rawWei, 'finney'));
    if (rawUnit !== 'ether') setEther(Web3.utils.fromWei(rawWei, 'ether'));
    if (rawUnit !== 'kether') setKEther(Web3.utils.fromWei(rawWei, 'kether'));
    if (rawUnit !== 'mether') setMEther(Web3.utils.fromWei(rawWei, 'mether'));
    if (rawUnit !== 'gether') setGEther(Web3.utils.fromWei(rawWei, 'gether'));
    if (rawUnit !== 'tether') setTEther(Web3.utils.fromWei(rawWei, 'tether'));
  }

  return (
    <Card>
      <CardContent sx={{
        mb: 0,
        pb: 0,
      }} >
        <Typography variant="h5" component="div" sx={{
          userSelect: 'none',
          pl: 1,
          pb: 2,
        }}
          onDragStart={(e) => { e.preventDefault(); }}>
          Unit Converter
        </Typography>

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">Wei</InputAdornment>,
          }}
          onChange={(e) => {
            setWei(e.target.value);
            updateResult(e.target.value, 'wei');
          }}
          value={wei}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">KWei</InputAdornment>,
          }}
          onChange={(e) => {
            setKWei(e.target.value);
            updateResult(e.target.value, 'kwei');
          }}
          value={kWei}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">MWei</InputAdornment>,
          }}
          onChange={(e) => {
            setMWei(e.target.value);
            updateResult(e.target.value, 'mwei');
          }}
          value={mWei}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">GWei</InputAdornment>,
          }}
          onChange={(e) => {
            setGWei(e.target.value);
            updateResult(e.target.value, 'gwei');
          }}
          value={gWei}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">Szabo</InputAdornment>,
          }}
          onChange={(e) => {
            setSzabo(e.target.value);
            updateResult(e.target.value, 'szabo');
          }}
          value={szabo}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">Finney</InputAdornment>,
          }}
          onChange={(e) => {
            setFinney(e.target.value);
            updateResult(e.target.value, 'finney');
          }}
          value={finney}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">Ether</InputAdornment>,
          }}
          onChange={(e) => {
            setEther(e.target.value);
            updateResult(e.target.value, 'ether');
          }}
          value={ether}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">KEther</InputAdornment>,
          }}
          onChange={(e) => {
            setKEther(e.target.value);
            updateResult(e.target.value, 'kether');
          }}
          value={kEther}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">MEther</InputAdornment>,
          }}
          onChange={(e) => {
            setMEther(e.target.value);
            updateResult(e.target.value, 'mether');
          }}
          value={mEther}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">GEther</InputAdornment>,
          }}
          onChange={(e) => {
            setGEther(e.target.value);
            updateResult(e.target.value, 'gether');
          }}
          value={gEther}
          margin="dense"
        />

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">TEther</InputAdornment>,
          }}
          onChange={(e) => {
            setTEther(e.target.value);
            updateResult(e.target.value, 'tether');
          }}
          value={tEther}
          margin="dense"
        />
      </CardContent>

      <CardActions></CardActions>
    </Card>
  );
}