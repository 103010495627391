import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActions, TextField } from '@mui/material';
import { useState } from 'react';
import Web3 from 'web3';

function Keccak256() {
  const [input, setInput] = useState("");
  const [keccak256, setKeccak256] = useState("");
  const [signature, setSignature] = useState("");

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setInput(e.target.value);

    if (e.target.value) {
      try {
        let keccak256 = Web3.utils.keccak256(e.target.value).substring(2);
        setKeccak256(keccak256);
        setSignature(keccak256.substring(0, 8));
        return;
      } catch (_error) { }
    }
    setKeccak256("");
    setSignature("");
  }

  return (
    <Card>
      <CardContent sx={{
        mb: 0,
        pb: 0,
      }} >
        <Typography variant="h5" component="div" sx={{
          userSelect: 'none',
          pl: 1,
          pb: 1,
        }}
          onDragStart={(e) => { e.preventDefault(); }}>
          Keccak256
        </Typography>

        <TextField sx={{
          userSelect: 'none',
        }}
          spellCheck="false"
          fullWidth
          label="Input"
          multiline
          rows={4}
          onChange={(e) => handleInputChange(e)}
          value={input}
          margin="normal"
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          fullWidth
          label="Keccak256"
          multiline
          rows={2}
          value={keccak256}
          margin="normal"
        />

        <TextField sx={{
          userSelect: 'none',
          mt: 1,
        }}
          spellCheck="false"
          fullWidth
          label="Signature"
          value={signature}
          margin="normal"
        />
      </CardContent>

      <CardActions sx={{
        pb: 0.5,
      }}></CardActions>
    </Card>
  );
}

export default Keccak256;